@import url('https://use.typekit.net/skv7lnc.css');
$font-family-sans-serif: "acumin-pro" !default;
/*
*::-webkit-scrollbar {
    display: none;
}
* {
    scrollbar-width: none;
}*/
$theme-colors: (
    "primary": #f39325,
    "secondary": #002c50,
    "darkgray": #646464,
    "gray": #505050,
    "lightgray": #f7f7f7,
    "iconcolor": #dddddd
);

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}
@import 'bootstrap/scss/bootstrap';

////////////
///LAYOUT///
////////////
.zammadoo-container {
    @include media-breakpoint-up(lg) {
        width: 100%;
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }
}
nav.zammadoo-topnav + * {
    padding-top: 4rem;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

//////////////////
///ALERT ON TOP///
//////////////////
nav + .alert {
    margin-top: 3rem;
}

//////////////////////
///// ONBOARDING /////
//////////////////////
.onboarding {
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: var(--gray);
        background-color: var(--gray);
    }
    p {
        line-height: 1.2;
    }
}

//////////////////////
/// MODAL FROM TOP ///
//////////////////////
.zammadoo-modal-fromtop {
    background-color: rgba(0,0,0,0.8);
    .modal-dialog {
        margin: 2rem;
        padding-top: 10%;
        .modal-content {
            border-radius: 1.5rem;
            .modal-header{
                border-bottom: unset;
            }
            .modal-footer{
                border-top: unset;
            }
        }
    }
}

/////////////////////////
/// MODAL FROM BOTTOM ///
/////////////////////////
.zammadoo-modal-frombottom {
    position: fixed;
    color: inherit;
    & > * {
        background-color: rgba(0,0,0,0.8);
    }
    .modal-wrapper {
        margin-top: 5%;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        .ion-page {
            .modal-header {
                border-bottom: unset;
                .close {
                    text-shadow: unset;
                    opacity: unset;
                }
            }
        }
    }
    &.zammadoo-bg-primary, &.zammadoo-bg-secondary {
        color: var(--white);
        .modal-wrapper {
            background-color: var(--primary);
            .close {
                color: var(--white);
            }
        }
    }
    &.zammadoo-bg-secondary {
        .modal-wrapper {
            background-color: var(--secondary);
        }
    }
    /* relevant for settings view */
    .settings {
        .edit-pics {
            img.rounded-zammadoo.profile {
                width: 85px;
                height: 85px;
            }
        }
        img.rounded-zammadoo.profile {
            width: 100px;
            height: 100px;
            border-width: medium !important;
        }
    }
}

/* search match detail fade-in card */
.match-card {
    &:not(.show) {
        max-height: 1rem;
        overflow: hidden;
    }
    &.show {
        height: 100%;
        padding: 4.5rem 1rem 0 1rem;
        background-color: rgba(255,255,255,0.8);
    }
}

/* circular buttons */
.btn {
    &.btn-circle {
        width: 32px;
        height: 32px;
        padding: 3px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 1.42857;
        &.btn-lg {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            font-size: 16px;
            line-height: 1.33;
        }
        &.btn-xl {
            width: 70px;
            height: 70px;
            padding: 10px 16px;
            border-radius: 35px;
            font-size: 24px;
            line-height: 1.33;
        }
    }
}

/* special circular Zammadoo buttons */
.zammadooZbutton.btn-circle {
    font-size: 24px;
    font-weight: bolder;
    line-height: 0.8;
    &.btn-xl {
        font-size: 48px;
        line-height: 1;
    }
}

/* special bell button */
button {
    .zammadoo-icon.bell {
        width: 24px;
        height: 24px;
    }
}

/* facebook button */
.btn {
    &.btn-facebook {
        background-color: #3B5998;
        color: #fff;
        border-width: 1px;
        border-style: solid;
        border-color: #263961;
        &:link, &:visited {
            color: #fff;
        }
        &:active, &:hover {
            background-color: #263961;
            color: #fff;
        }
    }
}

/* for date picker */
 .react-datepicker-wrapper {
     width: 100%;
 }

 /* Zammadoo special extends */
.btn-radiobtn--zammadoo.active:before {
    content: 'x';
    text-transform: lowercase !important;
}

.MuiSlider-valueLabel--zammadoo {
    &.PrivateValueLabel-offset-3, &.jss3 {
        top: unset;
        position: unset;
        transform: unset !important;
        .PrivateValueLabel-circle-4, .jss4 {
            border-radius: 50%;
            .PrivateValueLabel-label-5, .jss5 {
                color: white;
            }
        }
    }
}

img.rounded-zammadoo {
    object-fit: cover;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    &.profile {
        height: 55px;
        width: 55px;
    }
    @include media-breakpoint-up(sm) {
        height: 100px;
        width: 100px;
    }
    @include media-breakpoint-up(md) {
        height: 180px;
        width: 180px;
    }
    @include media-breakpoint-up(lg) {
        height: 240px;
        width: 240px;
    }
}

.close-white {
    .close {
        color: $white;
    }
}
///////////
///FEEDS///
///////////
.feeds {
    .feed-row {
        font-size: 0.65rem;
        border-radius: 1rem;
        background-color: var(--lightgray);
        max-width: calc(100% - 2rem);
    }
    .zammadoo-icon {
        width: 2rem;
        height: 2rem;
    }
    @include media-breakpoint-up(xs) {
    }
    @include media-breakpoint-up(sm) {
        .feed-row {
            font-size: 1rem;
        }
        .zammadoo-icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    @include media-breakpoint-up(md) {
        .feed-row {
            font-size: 1.25rem;
        }
        .zammadoo-icon {
            width: 3rem;
            height: 3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .feed-row {
            font-size: 1.5rem;
        }
        font-size: 1.5rem;
        .zammadoo-icon {
            width: 4rem;
            height: 4rem;
        }
    }
    .zammadoo-feeds-button, .zammadoo-matchcard-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &> .row:last-child {
        margin-bottom: 4.5rem;
    }
}

//////////////////
///   SEARCH   ///
//////////////////
.searches {
    nav.fixed-top {
        button.btn.btn-circle.border-0.ml-auto {
            visibility: hidden;
        }
    }
    nav.border-top.fixed-bottom {
        display: none;
    }
}
.btn-group-round .btn:first-child {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
}
.btn-group-round .btn:last-child {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
}

//////////////////
///MODAL SEARCH///
//////////////////
.zammadoo-modal-frombottom {
    &.activity {
        .modal-wrapper {
            .activity-accordion {
                .MuiCollapse-wrapperInner > div {
                    padding-left: 1rem;
                    .MuiAccordionDetails-root {
                        padding-right: 0.25rem;
                    }
                }
            }
        }
    }
}

////////////////
/// REGISTER ///
////////////////
.datepicker-zammadoo-register {
    .react-datepicker-popper {
        z-index: 2;
    }
}
.register, .deleteme, .verify {
    nav.fixed-top, nav.fixed-bottom {
        display: none;
    }
    div.main-frame {
        padding-top: 0 !important;
    }
}

//////////////////
/////PROFILE//////
//////////////////
.profile-axiom {
    min-height: 25vh;
    max-height: 30%;
    @include media-breakpoint-up(sm) {
        min-height: 200px;
        max-height: 30%;
    }
}

.upload {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
}

/////////////
///MATCHES///
/////////////
.matches {
    .MuiAccordion-root.Mui-expanded {
        margin-top: 2px;
    }
    .MuiPaper-elevation1 {
        box-shadow: unset;
        .MuiAccordionDetails-root.matchResultAccordion {
            padding-left: 1rem;
            padding-top: 1rem;
        }
    }
    .match-row {
        font-size: 0.75rem;
        border-radius: 1rem;
        background-color: var(--lightgray);
        max-width: calc(100% - 2rem);
        &+ .extendSearch {
            padding-top: 1rem;
        }
        .zammadoo-icon {
            width: 2rem;
            height: 2rem;
        }
    }
    .match-card {
        .zammadoo-icon {
            width: 2rem;
            height: 2rem;
        }
        .zammadoo-matchcard-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    @include media-breakpoint-up(sm) {
        .match-row {
            font-size: 1rem;
        }
        .zammadoo-icon {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    @include media-breakpoint-up(md) {
        .match-row {
            font-size: 1.25rem;
        }
        .zammadoo-icon {
            width: 3rem;
            height: 3rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .match-row {
            font-size: 1.5rem;
        }
        .zammadoo-icon {
            width: 4rem;
            height: 4rem;
        }
    }
}

/////////////
/// CHATS ///
/////////////
#root {
}

//////////////////////////
// DISABLE CONTEXT MENU //
//////////////////////////
html {
    -webkit-touch-callout: none; /* Disables long-touch menu */
    -webkit-user-select: none; /* Disable text selection (for Webkit) */
    user-select: none; /* Disable text selection (standard syntax) */
}

/////////////////////////
/// ACCORDION MATCHES ///
/////////////////////////
.MuiAccordionDetails-root.matchResultAccordion {
    display: block;
    padding: 0;
}

////////////////////////////
/// ACCORDION ACTIVITIES ///
////////////////////////////
.activity-accordion.modal-body {
    .MuiPaper-root {
        color: unset;
        background-color: unset;
    }
    .MuiIconButton-root {
        color: var(--white);
    }
    .MuiPaper-elevation1 {
        box-shadow: unset;
    }
    .MuiAccordion-root:before {
        display: none;
    }
}

////////////////
//// HURRAY ////
////////////////
.hurray {
    img.rounded-zammadoo {
        width: 100px;
        height: 100px;
    }
}

/////////////
//// IOS ////
/////////////
@mixin notch () {
    .onboarding .carousel-item .bg-primary {
        padding-top: 5rem !important;
    }
}
#root {
    .ios {
        nav.fixed-top {
            padding-top: 2.5rem !important;
            .top-navigation-titel {
                padding-top: 1.6rem !important;
            }
        }
        div.main-frame:not(.not-logged-in) {
            padding-top: 5rem !important;
        }
        &.register, &.deleteme, &.verify {
            div.main-frame {
                padding-top: 0 !important;
            }
        }
        .tm-zammadoo-ug {
            padding-bottom: 2rem !important;
        }
        .profile-axiom {
            min-height: 240px;
            max-height: 30%;
            h5 {
                margin-bottom: 4rem !important;
            }
        }
        .separator {
            margin-bottom: 5.5rem !important;
            &.profile-view {
                margin-bottom: 13.5rem !important;
            }
        }
        nav.navbar.fixed-bottom {
            padding-bottom: 2rem !important;
        }
        .onboarding-bottom {
            padding-bottom: 2rem !important;
        }
        div.main-frame .onboarding .carousel-item .bg-primary {
            padding-top: 1rem !important;
            @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
                padding-top: 1rem !important;
            }
        }
        div.main-frame {
            /* iphone X , XS, 11 Pro, 12 Mini */
            @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
                @include notch();
            }
            /* iphone 12, 12 Pro */
            @media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
                @include notch();
            }
            /* iphone XR, 11 */
            @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
                @include notch();
            }
            /* iphone XS Max, 11 Pro Max */
            @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
                @include notch();
            }
            /* iphone 12 Pro Max */
            @media only screen and (min-device-width : 428px) and (max-device-height : 926px) and (-webkit-device-pixel-ratio : 3) {
                @include notch();
            }
        }
    }
}
.zammadoo-modal-frombottom.zammadoo-ios {
    .modal-wrapper {
        margin-top: 25%;
    }
}
