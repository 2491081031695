* {
    box-sizing: border-box;
}

.messages {
    position: relative;
    list-style: none;
    padding: 20px 10px 0 10px;
    margin: 0;
    height: 100%;
    overflow: scroll;
}
.messages .message {
    clear: both;
    overflow: hidden;
    margin-bottom: 20px;
    transition: all 0.5s linear;
    opacity: 0;
}

.float-left .arrow {
    left: .5rem;
    height: 1rem;
    margin: .3rem 0;
    position: absolute;
    display: block;
    width: 1rem;
}
.float-left .arrow:before, .float-left .arrow:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.float-left .arrow:before {
    left: 0;
    border-right-color: #e9ecef;
    border-width: .5rem .5rem .5rem 0;
}
.float-left .arrow:after {
    left: 1px;
    border-right-color: #e9ecef;
    border-width: .5rem .5rem .5rem 0;
}

.float-right .arrow {
    right: 0;
    height: 1rem;
    margin: .3rem 0;
    position: absolute;
    display: block;
    width: 1rem;
}
.float-right .arrow:before, .float-right .arrow:after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}
.float-right .arrow:before {
    left: 0;
    border-left-color: #dc9131;
    border-width: .5rem 0 .5rem .5rem;
}
.float-right .arrow:after {
    left: 1px;
    border-left-color: #dc9131;
    border-width: .5rem 0 .5rem .5rem;
}
